<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>Article</v-card-title>
        <v-card-title>
          <v-row no-gutter>
            <v-col cols="12" sm="6" md="8">
              <v-form ref="form" v-model="el.validForm" lazy-validation>
                <div>
                  <v-switch
                    v-model="article.enabled"
                    label=" Enabled"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="article.url_title"
                    type="text"
                    label="URL title"
                    placeholder="e.g your-article-url-title"
                    :rules="[rule.required]"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="article.site_title"
                    type="text"
                    label="Site title"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="article.meta_description"
                    type="text"
                    label="Meta description"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="article.meta_keywords"
                    type="text"
                    label="Meta keywords"
                    outlined
                  />
                </div>
                <div class="mb-5">
                  <v-tabs v-model="el.tabs" grow>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Dutch</v-tab>
                    <v-tab>English</v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-items v-model="el.tabs">
                    <v-tab-item :eager="true">
                      <v-card class="pt-4" flat>
                        <div>
                          <v-text-field
                            v-model="article.title"
                            type="text"
                            label="Title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <html-editor
                            v-model="article.body"
                            placeholder="Article body"
                            :className="
                              el.bodyError ? 'article-body-error' : ''
                            "
                          ></html-editor>
                          <v-slide-y-transition>
                            <div
                              v-if="el.bodyError"
                              class="article-body-error--detail red--text text--lighten-6 mx-3"
                            >
                              Required.
                            </div>
                          </v-slide-y-transition>
                        </div>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item :eager="true">
                      <v-card class="pt-4" flat>
                        <div>
                          <v-text-field
                            v-model="article.title_en"
                            type="text"
                            label="Title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <html-editor
                            v-model="article.body_en"
                            placeholder="Article body"
                            :className="
                              el.bodyError ? 'article-body-error' : ''
                            "
                          />
                          <v-slide-y-transition>
                            <div
                              v-if="el.bodyEnError"
                              class="article-body-error--detail red--text text--lighten-6 mx-3"
                            >
                              Required.
                            </div>
                          </v-slide-y-transition>
                        </div>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-form>
            </v-col>
            <v-col cols="6" md="4">
              <v-btn
                class="mb-4"
                block
                elevation="2"
                :loading="getArticlePreviewLoading"
                @click="showPreviewArticle(selectedLang)"
              >
                Preview article page
              </v-btn>
              <v-btn
                @click="save"
                :loading="getArticlesLoading"
                color="primary"
                elevation="2"
                block
              >
                {{ isCreate ? "Save" : "Update" }} article
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </base-card>
      <template-preview
        v-if="preview.show"
        v-model="preview.show"
        :selected-lang="selectedLang"
        :src="preview.src"
        @language-select="showPreviewArticle"
      ></template-preview>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import rule from "@/shared/rules";
import _ from "lodash";
import store from "@/store";
import Lightbox from "@/components/Lightbox";
import TemplatePreview from "@/components/TemplatePreview";
import HtmlEditor from "@/components/HtmlEditor";
import { LANGUAGE, DEFAULT_LANGUAGE } from "@/shared/constants";

export default {
  metaInfo: {
    title: "Article"
  },
  components: { HtmlEditor, Lightbox, TemplatePreview },
  data() {
    return {
      rule,
      id: _.get(this.$route, "params.id"),
      article: {
        enabled: false,
        url_title: null,
        title: null,
        title_en: null,
        body: null,
        body_en: null,
        site_title: null,
        meta_keywords: null,
        meta_description: null
      },
      el: {
        validForm: false,
        tabs: null,
        bodyError: false,
        bodyEnError: false
      },
      preview: {
        show: false,
        src: null
      },
      selectedLang: DEFAULT_LANGUAGE,
      languages: LANGUAGE
    };
  },
  mounted() {
    if (!this.isCreate) {
      const article = _.cloneDeep(store.getters.getArticle(this.id));
      this.article = article;
    }
  },
  methods: {
    ...mapActions(["createArticle", "updateArticle", "previewArticle"]),
    getPayload() {
      const payload = new FormData();

      _.forOwn(this.article, (value, key) => {
        let val = value;

        if (key === "enabled") {
          val = key === "enabled" ? Number(value) : value;
        }

        payload.append(key, val === null ? "" : val);
      });

      if (!this.isCreate) {
        _.each(["id", "created_at", "updated_at"], key => payload.delete(key));
      }

      return payload;
    },
    async showPreviewArticle(lang) {
      this.selectedLang = lang;
      this.validateArticleBody();

      if (!this.$refs.form.validate() || !this.validArticleBody) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const payload = this.getPayload();
      this.preview.src = await this.previewArticle({
        id: this.id,
        payload,
        lang
      });
      this.preview.show = true;
    },
    save() {
      this.validateArticleBody();

      if (!this.$refs.form.validate() || !this.validArticleBody) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const payload = this.getPayload();
      this.isCreate
        ? this.createArticle(payload)
        : this.updateArticle({ id: this.id, payload });
    },
    validateArticleBody() {
      this.el.bodyError = !this.htmlToText(this.article.body || "");
      this.el.bodyEnError = !this.htmlToText(this.article.body_en || "");
    },
    htmlToText(html) {
      return html.replace(/<[^>]*>?/gm, "");
    }
  },
  computed: {
    ...mapGetters([
      "getModels",
      "getArticlesLoading",
      "getArticlePreviewLoading"
    ]),
    isCreate() {
      return _.get(this.$route, "meta.kind") === "create";
    },
    validArticleBody() {
      return !this.el.bodyError && !this.el.bodyEnError;
    }
  }
};
</script>
<style lang="scss" scoped>
.article-body-error {
  border: 2px solid #ff5252;
  border-radius: 4px;
}

.article-body-error--detail {
  font-size: 0.75rem;
}
</style>
